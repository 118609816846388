import React from 'react'
import { getTimezone } from '../../../utility/UserUtility'
import moment from 'moment'
const SessionWeekStart = ({sessionData}) => {

  const timeConvert=(raw)=>{
    var times=raw.split(':');
    var hours = times[0];
    var minutes= times[1];
    var secones= times[2];
    var AmOrPm = hours >= 12 ? 'pm' : 'am';
    hours = (hours % 12) || 12;
    var finalTime = hours + ":" + minutes + AmOrPm; 
    return finalTime;


  }

  return (
    <div className={"inline-flex relative items-center justify-center w-full "}>

       { <div className=" pr-2.5 text-center text-gray-500 text-sm font-medium  leading-tight">{sessionData!==null&&sessionData!==undefined&&sessionData.start_time!==null?timeConvert(sessionData.start_time):"n/a"}</div>}

    <hr className="w-full h-0.5  bg-gray-200 border-0 rounded "/>
    <div className="absolute px-4 -translate-x-1/2 bg-white left-1/2 text-center text-slate-600 text-sm font-medium  leading-tight">
       Week {sessionData.plan_week} Started
    </div>
</div>
  )
}

export default SessionWeekStart