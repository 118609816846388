import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Spacer from '../components/Spacer';
import Page from '../components/Page';
import Input from '../components/input/Input';
import API from '../services/API';
import { useHistory } from "react-router-dom";
import { TailSpin	 } from  'react-loader-spinner'
import PatientProfile from './PatientProfile';
import cookie from 'react-cookies';
import moment from 'moment';
import { getTimezone } from '../utility/UserUtility'; 
import { getDuration } from '../utility/Visit';
import { getDurationComplete } from '../utility/Visit';
import ActivityPanel from '../components/compositions/ActivityPanel';
import VisitsPanel from '../components/compositions/VisitsPanel';
import RomPanel from '../components/compositions/RomPanel';
import CoachingPlanPanel from '../components/compositions/CoachingPlanPanel';

const PatientActivityPage = () => {

  const patientId = useParams().id;
    const role = cookie.load('role') ? cookie.load('role') : 'None';
    const [patientData, setPatientData] = useState(null);
    const [vetkitVersion, setVetkitVersion]= useState(null)
    const [physician, setPhysician] =useState(null);
    const [errors, setErrors] = useState(null);
    const [loading, setLoading] = useState(true);
	
	  const [outcomeReports, setOutcomeReports] = useState(null);
    const [download, setDownload] = useState(false);
    const timezone = getTimezone();

    const [sessions, setSessions] = useState(null);
    const [activities, setActivities] = useState(null);
    const [roms, setRoms] = useState(null);
    const [coachingPlanData, setCoachingPlanData]= useState(null);
    const [currentWeek, setCurrentWeek]= useState(null)
    const [NoDataFound,setNotDataFound]= useState(true);
    const [weeklyScores, setWeeklyScores]= useState(null);


    useEffect(()=>{
  
      if(NoDataFound&&patientData!==null)
      {getSessions()}


  },[NoDataFound])

    useEffect(()=>{
      if(patientData===null)
      {
        getPatientData();
      }
      if(!NoDataFound){
      if(activities===null)
      {
        getActivityData();
      }
      if(roms===null)
      {
        getRomData();
      }
      if(coachingPlanData===null)
      {
        getStats();
      }
      if(sessions===null)
        {
          getSessions();
        }
     

      if(coachingPlanData!==null&&activities!==null&&roms!==null&&patientData!==null&&sessions!==null)
      {
        setLoading(false);
      }
    }



  },[coachingPlanData,roms,activities,patientData,sessions])

  

  const getPatientData = () => {
		API.get(`/patients/${patientId}/`)
			.then(response => {
				setPatientData(response.data);
        

        console.log(response.data)
        console.log(response.data.coaching_plan)
        console.log(response.data.last_coaching_plan_week)


				if(response.data.coaching_plan!==undefined)
				{
					setCurrentWeek(response.data.coaching_plan["plan_week"])
          setNotDataFound(false);

				}
        else if(response.data.last_coaching_plan_week!==undefined)
          {
            setCurrentWeek(response.data.last_coaching_plan_week)
            setNotDataFound(false);
  
          }
        else
        {
          setNotDataFound(true);
          
        }
				if(response.data.weekly_checkins!==undefined && response.data.coaching_plan!==undefined)
				{
					var week = parseInt( response.data.coaching_plan["plan_week"])
					if(week>=1)
					{

						var scores=[response.data.weekly_checkins]

						for (let i =1; i < week+1; i++) {
							if(response.data.weekly_checkins[i.toString()]!==undefined){
							var check= response.data.weekly_checkins[i.toString()][0];
							check["week"]=i
							scores.push(check);
							}
							

						 }
						setWeeklyScores(scores)

					}
				}
				
			})
			.catch(error => console.error(error));
	}

  

  const getStats=()=>{
    API.get(`/patient_usage_stats/?detail=true&patient_id=${patientId}&exclude_visits_without_coaching_plans=true`)
			.then(response => {			
        console.log(response.data.results)
				setCoachingPlanData(response.data.results);
			})
			.catch(
				error => {
					console.error(error)
          setErrors(error)
					setCoachingPlanData([]);
				}
				);
  }

  const getRomData=()=>{
    API.get(`/roms/?patient=${patientId}&limit=5000&most_recent_coaching_plan=true`).then(response => {

      if(response.data.results.length>0)
			{
			  setRoms(response.data.results);

	  
			}
      else
      {
        setRoms([]);
      }
			
		}).catch(error => {
			console.log(error)
      setErrors(error);
			setRoms([]);

		}
    )
  }
  
  const getSessions=()=>{
    API.get(`/session_history/?patient_id=${patientId}&limit=5000&most_recent_coaching_plan=true`). then(response=>{
      console.log(response.data);
      console.log(NoDataFound)
      if(NoDataFound)
        {
          if(Object.keys(response.data).length>0)
            {
            setCurrentWeek(  Object.keys(response.data)[Object.keys(response.data).length-1]);
              setNotDataFound(false);
            }
          setLoading(false)
        }
      setSessions(response.data);
    })

  }

  const getActivityData=()=>{
    API.get(`/exercises/?visit__patient=${patientId}&limit=5000&most_recent_coaching_plan=true`).then(response => {
			if(response.data.results.length>0)
			{
			  setActivities(response.data.results);
        
        
		//setSessions(newDates);

	  
			}
      else
      {
        setActivities([]);
       // setSessions([])
      }
			
		}).catch(error => {
			console.log(error)
      setErrors(error);
			//setActivities([]);

		}
		
		)

  }

 


  return (
    <PatientProfile>
    {
    loading===false&&NoDataFound===false ?
       (NoDataFound||!(coachingPlanData&&coachingPlanData[0].plan_stats!==undefined&&coachingPlanData[0].plan_stats!==null)?
       (<div className=" w-full h-full justify-center gap-6 items-center inline-flex flex-col	  ">
    <div className="w-14 h-14 p-3.5 bg-[#ffe9e2] rounded-full border border-gray-50 justify-center items-center inline-flex">
        <img className="w-7 h-7 relative flex-col justify-start items-start flex" src="/icons/data.svg" />
    </div>
    <div className=' text-wrap w-1/2	[@media(min-width:1600px)]:w-1/4  break-words '>
    <div className="  self-stretch text-center text-gray-700 text-3xl font-semibold  leading-[38px]">No Data</div>
        <div className=" break-words inline-block		 text-center text-gray-500 text-base font-normal  ">There is no activity data to display for this patient yet. Once they have an active coaching plan and begin completing VET activities, you can track their usage data here.</div>
   
    </div>
    
        </div>)        :
       (<div className='w-full h-[99%] max-[1800px]:h-[99%]	  grid min-[1200px]:grid-rows-2 grid-cols-2  max-[1200px]:grid-cols-1 gap-4 mx-4 my-2'>
        <div className=" 	justify-center items-center inline-flex    max-[1200px]:w-full max-[1200px]:h-[400px]   min-w-0 min-h-0	 relative rounded-[10px] shadow border border-gray-200">
       <ActivityPanel activities={activities} currentWeek={currentWeek} />
        </div>
        <div className=" 	justify-center items-center inline-flex    max-[1200px]:w-full max-[1200px]:h-[400px]  min-w-0   min-h-0	relative rounded-[10px] shadow border border-gray-200">
       <RomPanel roms={roms} currentWeek={currentWeek} />
        </div>
       
        <div key={sessions} className=" 	justify-start items-center inline-flex  max-[1200px]:w-full max-[1200px]:h-[400px]   min-w-0   min-h-0	 relative rounded-[10px] shadow border border-gray-200">
      

       <VisitsPanel visits={sessions} current={currentWeek} patientId={patientId} name={`${patientData.first_name}_${patientData.last_name}`} rawData={activities}/>

       </div>
       <div className=" 	justify-center items-center inline-flex    max-[1200px]:w-full max-[1200px]:h-[400px]   min-w-0   min-h-0  relative rounded-[10px] shadow border border-gray-200">
      { coachingPlanData!==null&&currentWeek!==null&&coachingPlanData.length>0? <CoachingPlanPanel currentWeek={currentWeek} stats={coachingPlanData&&coachingPlanData[0].plan_stats!==undefined&&coachingPlanData[0].plan_stats!==null?coachingPlanData[0].plan_stats:-1}/>:<div className=" w-full h-[90%] justify-start items-center inline-flex flex-col 	">No Data Found</div>}
        </div>
       </div>)):
        (<div className=" w-full h-full justify-center items-center inline-flex flex-col	">
        <TailSpin	
                    height="100"
                    width="100"
                    color='grey'
                    ariaLabel='loading'
                  />
                  <br /> <br />
                  <div/>
              <h4 className="">Loading...</h4>
        </div>)
    }

</PatientProfile>
  )
}

export default withRouter( PatientActivityPage)