import React, { useState } from 'react';
import { withRouter } from 'react-router';

const HeaderButton = (title,img,click) => {
  return (
    <React.Fragment>

    <button className='h-9 px-3 py-2 bg-white rounded-lg shadow border border-gray-300 justify-center items-center gap-1 inline-flex' onClick={click}>
        {img&&<img className="w-5 h-5 relative" src={img} />}
<div className="px-0.5 justify-center items-center flex">
<div className="text-gray-700 text-sm font-semibold  leading-tight">{title}</div>
</div>
    </button>
    </React.Fragment>

  )
}

export default withRouter(HeaderButton)