import React, { useState, useEffect, useRef } from 'react';
import { withRouter, useParams, useLocation } from 'react-router';
import Page from '../components/Page';
import API from '../services/API';
import { TailSpin	 } from  'react-loader-spinner' 
import Spacer from '../components/Spacer';
import cookie from 'react-cookies';
import { unescape } from 'lodash';
import { useHistory } from "react-router-dom";


const PatientProfile = ({ children , redirect=false, forceOverlFlow=false, errors=null,clear=false, successes=null }) => {

	const role = cookie.load('role') ? cookie.load('role') : 'None';
	const patientId = useParams().id;
    const [patientData, setPatientData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [vetkitVersion, setVetkitVersion]= useState(null)
    const [physician, setPhysician] =useState(null);
    const [tpa, setTpa] =useState(null);
    const [insurer, setInsurer] =useState(null);
    const [provider, setProvider] =useState(null);
    const [headerInfo, setHeaderInfo] =useState(null);

	let history = useHistory(); 


    const breadcrumbLinks = () => {
        return [
            {
                label: "Patients",
                url: "/"
            },
            {
                label: (patientData!==null?patientData.first_name+" "+patientData.last_name:"Patient"),
                url: `/patient/${patientId}`
            }
            
        ]
    }

    useEffect(() => {
		if (patientId==null) {
			history.push('/');
		}

		if (patientData===null) {
			getPatientData();
			
		}
	});

    const toCoach=()=>{
        history.push(`/patient/${patientId}/coaching-plan`);

    }

    const toActivity=()=>{
        history.push(`/patient/${patientId}/activity`);

    }

    const toReports=()=>{
        history.push(`/patient/${patientId}/reports`);

    }
    useEffect(()=>{console.log(errors)},[errors])

    useEffect(() => {
        let tabs=[]
        if(role==="Coach"||role==="Facilitator")
        {
            tabs.push({text:"Coaching Plan",click:toCoach,current:(window.location.href.includes("coaching-plan")?true:false)})
        }
        tabs.push({text:"Activity",click:toActivity,current:(window.location.href.includes("activity")?true:false)})
        if(role==="Coach"||role==="Facilitator")
        {
            tabs.push({text:"Reports",click:toReports,current:(window.location.href.includes("reports")?true:false)})
        }
		setHeaderInfo(
            {trail:breadcrumbLinks(),body:{subheader:(patientData!==null?"#"+patientId+" | "+getGender(patientData.profile.gender)+" | "+getPatientHeight():""),name:(patientData!==null?patientData.first_name+" "+patientData.last_name:""),vetkit:vetkitVersion,info:({tpa:tpa,provider:provider,insurer:insurer,physician:physician}),tabs: tabs,sideButton:{img:"/icons/edit.svg", text:"Edit Patient", click:EditPatient}}}
        );
	},[patientData]);











    
    
    const Redirect=()=>{
        if(redirect)
        {
            if(role==="Coach"||role==="Facilitator")
            {
                history.push(`/patient/${patientId }/coaching-plan`);
            }
            else
            {
                history.push(`/patient/${patientId }/activity`);

            }
        }
    }



    const getPatientData = () => {

        var pdata = null;
        

   
        
		API.get(`/patients/${patientId}`)
			.then(response => {
                var data= response.data;
			//	console.log("Patient data", data);
            //    console.log(response.data)
				setPatientData(data);
                if(data.client!==undefined&&data.client.version!==undefined)
                {
                    var val= data.client.version.split("+unitycloudbuild")[0];
                if(val.includes("prod")){val=val.split("-")[0]}
                setVetkitVersion(val);
                }
                data.physician_name!==undefined?setPhysician(data.physician_name ):setPhysician("n/a");   
                data.provider_name!==undefined?setProvider(data.provider_name ):setProvider("n/a");   
                if(data.profile!==undefined)
                {
                    var profile= data.profile
                    profile.third_party_administrators!==undefined?setTpa(profile.third_party_administrators):setTpa("n/a");   
                    profile.insurers!==undefined?setInsurer(profile.insurers):setInsurer("n/a");   

                }
                else{
                    setTpa("n/a");
                    setInsurer("n/a");
                }
                 
                if(redirect){
                    Redirect()
                }
                else
                {
                setLoading(false);
                }
			})
			.catch(error => console.error(error));
        
	}

    const getPatientHeight = () => {
		let feet = patientData.profile.height * 3.2808;
		var inches = Math.round((feet - Math.floor(feet)) * 12)
		if(inches>=12)
		{
			inches-=12
			feet+=1

		}
		return `${Math.floor(feet)}' ${inches}"`;
	}

    const getGender = (gender) => {
		switch (gender) {
			case 0:
				return "Female";
				break;
			case 1:
				return "Male";
				break;
			default:
				return "Nonbinary";
		}
	}

    const EditPatient=()=>{
        if(patientId!==null&&patientId!==undefined){
        history.push(`/patient/${patientId }/edit`);
        }
    }




  return (
    <Page clear={clear} errors={errors} successes={successes} headerObj={headerInfo!==null?headerInfo:{trail:breadcrumbLinks()}}  internal={true}>
            <Spacer headerObj={headerInfo!==null?headerInfo:{trail:breadcrumbLinks()}} internal={true}/>
            {!loading?
            <div className={"w-full h-full  justify-center items-start inline-flex whitespace-nowrap max-[1200px]:overflow-auto  "+(forceOverlFlow?"overflow-y-auto	":"")}>
                {children}
            </div>
            :
            <div className=" w-full h-full justify-center items-center inline-flex flex-col	">
	<TailSpin	
				height="100"
				width="100"
				color='grey'
				ariaLabel='loading'
			  />
			  <br /> <br />
			  <div/>
		  <h4 className="">Loading Patient Data...</h4>
	</div>
            }
    </Page>
  )
}

export default  PatientProfile