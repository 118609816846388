import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import { TailSpin	 } from  'react-loader-spinner'
import cookie from 'react-cookies';
import { Chart as ChartJS, defaults } from "chart.js/auto";
import {  Line } from "react-chartjs-2";
import DropdownInput from '../input/DropdownInput';
import { LabelList } from 'recharts';
import { getDurationComplete  } from '../../utility/Visit';
import { getDuration } from '../../utility/Visit';
import Input from '../input/Input';
import moment from 'moment';
import CustomDropDown from './CustomDropDown';




const ActivityPanel = ( {activities=null, currentWeek=-1}) => {
  const [activityTypes, setActivityTypes]= useState(null);
  const [currentType,setCurrentType]=useState(null);
  const [typeOptions,setTypeOptions]=useState(null);
  const [data,setData] = useState([])
  const [dataHolder,setDataHolder] = useState([])

  const [countData,setCountData] = useState([])

  const [labels,setLabels] = useState([])
  const [movementActivity,setMovementActivityData] = useState(true)
  const [chartTypeScore,setChartTypeScore]= useState(false);
  const [activityData,setActivityData] = useState(null)


  const custom_canvas_background_color = {
    id: 'custom_canvas_background_color',
    beforeDraw: (chart, args, options) => {

      const {
        ctx,
        chartArea: { top, right, bottom, left, width, height },
        scales: { x, y },
      } = chart;
      ctx.save();
      ctx.fillStyle='rgba(0,255,0, 0.2)';
      ctx.fillRect(left,top,width,y.getPixelForValue(4000)-y.getPixelForValue(5000));
      ctx.fillStyle='rgba(173,255,47,0.3)'
      ctx.fillRect(left,y.getPixelForValue(4000),width,y.getPixelForValue(2500)-y.getPixelForValue(4000));
      ctx.fillStyle='rgba(255, 255, 0, 0.2)'
      ctx.fillRect(left,y.getPixelForValue(2500),width,y.getPixelForValue(1000)-y.getPixelForValue(2500));
      ctx.fillStyle='rgba(255, 165, 0, 0.2)'
      ctx.fillRect(left,y.getPixelForValue(1000),width,y.getPixelForValue(0)-y.getPixelForValue(1000));


      ctx.restore();
    },
  };

  const custom_canvas_background_color2 = {
    id: 'custom_canvas_background_color',
    beforeDraw: (chart, args, options) => {
      const {
        ctx,
        chartArea: { top, right, bottom, left, width, height },
        scales: { x, y },
      } = chart;
      ctx.save();
      ctx.fillStyle='rgba(0,0,0, 0)';
      ctx.fillRect(left,top,width,height);
     


      ctx.restore();
    },
  };

  const tooltipCallback= 
  {
      label:(context)=>{
        console.log(context)

        return dataHolder[context.dataIndex].toString();
      },
      
      labelPointStyle: function(context) {
        return {
            pointStyle: 'rect',
        };
    },

      title: (context)=>{
        return ["Week "+context[0].label]
    },
    footer: (context)=>{
      return [ContextLevel(context)]
    }, 

    labelColor: function(context) {
      var color = ContextColor(context)
      return {
        borderColor: color,
        backgroundColor: color,
        borderWidth: 2,
      };
    },

  }

  const tooltipCallbackCount= 
  {
                
    labelPointStyle:(context)=>{
      return{
        pointStyle: GetActivityBadge( activityData[currentType]["type"],Number.parseInt( context.formattedValue))
      }
    },

      title: (context)=>{
        return [context[0].label]
    }
  }

  useEffect(()=>{
    var types=[]
    var actData={};
    var week= -1

    if(activities!==null&&activities!==undefined){
      for (let e in activities) {
        if(!types.includes(activities[e].activity_name))
        {types.push(activities[e].activity_name)}
        if(actData[activities[e].activity_name]===undefined)
        {

          actData[activities[e].activity_name]={}
          actData[activities[e].activity_name]["motionTime"]=getDuration(activities[e])

          actData[activities[e].activity_name]["starts"]=1
          if(activities[e].status==="Completed")
          {
            actData[activities[e].activity_name]["completions"]=1

          }
          actData[activities[e].activity_name]["type"]= activities[e].activity_type;
          if(activities[e].activity_type===3){
            actData[activities[e].activity_name]["scores"]={}

            week= activities[e].coaching_plan_week;
            actData[activities[e].activity_name]["scores"][week]=(activities[e].scores.score!==undefined?activities[e].scores.score:0)

          }
          week= activities[e].coaching_plan_week;

          actData[activities[e].activity_name]["count"]={}
          actData[activities[e].activity_name]["count"][week]=(activities[e].status==="Completed"?1:0)


        }
        else
        {
          actData[activities[e].activity_name]["motionTime"]+=getDuration( activities[e])

          actData[activities[e].activity_name]["starts"]+=1
          if(activities[e].status==="Completed")
          {
            actData[activities[e].activity_name]["completions"]+=1

          }
            if(activities[e].activity_type===3){
              
              var week= activities[e].coaching_plan_week;
              if(actData[activities[e].activity_name]["scores"][week]===undefined){
              actData[activities[e].activity_name]["scores"][week]=(activities[e].scores.score!==undefined?activities[e].scores.score:0) 

            }
              else{
                week= activities[e].coaching_plan_week;

                var score=(activities[e].scores.score!==undefined?activities[e].scores.score:0);
                if(actData[activities[e].activity_name]["scores"][week]<score)
                {
                    actData[activities[e].activity_name]["scores"][week]=score;
                }
              }

          }
          week= activities[e].coaching_plan_week;

          if(actData[activities[e].activity_name]["count"][week]===undefined)
          {
            actData[activities[e].activity_name]["count"][week]=(activities[e].status==="Completed"?1:0)
          }
          else{
            actData[activities[e].activity_name]["count"][week]+=(activities[e].status==="Completed"?1:0)
          }
        }
      }
  types.sort();

  var options=[]
  var i =0
  types.forEach(e=>{
    options.push(
      {
        id: i,
        name: e,
        value: e
      }
    );
    i++;
  })

if(currentType===null){
  setCurrentType(types[0]);
}
  setTypeOptions(options);
  setActivityTypes(types);
  setActivityData(actData);

    }
  },[activities])


  useEffect(()=>{
    if(currentType!==null&&activityData!==null&&currentWeek!==-1&&Object.keys(activityData).length>0)
    {
      var tempdata=[];
      var tempdataHolder=[];

      var tempCountData=[];
      var labels=[]
      for(var i=1;i<=currentWeek;i++)
      {

        labels.push(i);
        if(activityData[currentType]["type"]===3){
        tempdata.push(activityData[currentType]["scores"][i]!==undefined?(activityData[currentType]["scores"][i]>5000?5000:activityData[currentType]["scores"][i]):null)
        tempdataHolder.push(activityData[currentType]["scores"][i]!==undefined?activityData[currentType]["scores"][i]:null)

        }
        tempCountData.push(activityData[currentType]["count"][i]!==undefined?activityData[currentType]["count"][i]:null)

      }
      setData(tempdata);
      setDataHolder(tempdataHolder)
      setCountData(tempCountData);
      setLabels(labels);
      setMovementActivityData(activityData[currentType]["type"]===3);
    }
    else{
      setData([])
      setCountData([])
      setLabels([])
    }
    

  },[currentType])

  useEffect(()=>{
    if(!movementActivity)
    {
      setChartTypeScore(false);
    }
    else
    {
      setChartTypeScore(true)
    }
    

  },[movementActivity])


  const ContextColor=(context)=>{
    let raw =(context.raw)
    let level =""
    switch(true)
      {
       
        case raw<1000:
          level="rgba(255, 165, 0, 0.2)";
          break;
        case raw<2500:
          level= 'rgba(255, 255, 0, 0.2)';
          break;
        case raw<4000:
          level='rgba(173,255,47,0.3)'
          break;
        default:
          level="rgba(0,255,0, 0.2)"
          break;
      }

    return level
  }

  const ContextLevel=(context)=>{
    let raw =(context[0].raw)
    let level =""
    switch(true)
      {
       
        case raw<1000:
          level="Keep Going ";
          break;
        case raw<2500:
          level= 'Good';
          break;
        case raw<4000:
          level='Great'
          break;
        default:
          level="Excellent"
          break;
      }
      
    return level
  }

  const GetActivityBadge=(actType,rank)=>{
		var rankString= badgeParse(rank);
    var actString = ActivityTypeParse(actType);
		var path="/icons/badges/"+actString+"Activity_"+rankString+".png";
    var image= new Image(19,19)
    image.src=path;
    return image;
	}

  const ActivityTypeParse=(actType)=>{
    if(actType===1)
		{return "Coaching"}
		if(actType===2)
		{return "Meditation"}
		if(actType===3)
		{return "Movement"}
  }

	const badgeParse=(rank)=>{
		if(rank===1)
		{return "Bronze"}
		if(rank===2)
		{return "Silver"}
		if(rank>-3)
		{return "Gold"}
		
	
	}

  const RenderTable=(type)=>{
    

    var table=[];
    var total= activityData[currentType]["motionTime"]
    var avg= (total/activityData[currentType]["starts"])

   let totalString =total>0? `${moment.duration(total).hours() > 0 ? (moment.duration(total).hours() + "h and ") : ""}${moment.duration(total).minutes()?moment.duration(total).minutes() +"m ":""}  ${moment.duration(total).seconds()>0? moment.duration(total).seconds()+"s":""}` : `no data found`
   let avgString =avg>0? `${moment.duration(avg).hours() > 0 ? (moment.duration(avg).hours() + "h and ") : ""}${moment.duration(avg).minutes()?moment.duration(avg).minutes() +"m ":""}  ${moment.duration(avg).seconds()>0? moment.duration(avg).seconds()+"s":""}` : `no data found`
   
    table.push(
      <tr className=' border-y-0  even:bg-white odd:bg-white '  >
        <td className=" w-1/2	border-x-0 border-t-0 border-b-0 px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px]  "><span className='text-gray-900 	'>Starts</span><div className='float-end 	'>{activityData[currentType]["starts"]}</div> </td>
        <td className="	w-1/2 border-x-0 border-t-0 border-b-0 px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px] "><span className='text-gray-900 	'>Total Motion Time</span><div className='float-end 	'>{totalString}</div> </td>
      </tr>
    )
    table.push(
      <tr className=' border-y-2  even:bg-white odd:bg-white '  >
        <td className="	w-1/2 border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px] "><span className='text-gray-900 	'>Completions</span><div className='float-end 	'>{activityData[currentType]["completions"]}</div> </td>
        <td className="	w-1/2 border-x-0 px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px] "><span className='text-gray-900 	'>Average Motion Time</span><div className='float-end 	'>{avgString}</div> </td>
      </tr>
    )
    

    return table;
  }

 



  return (
    
      <React.Fragment>
      { (activities===null || activityTypes===null ||currentType===null||typeOptions===null||activityData===null||currentWeek===-1)?
          (<div className=" w-full h-full justify-start items-center inline-flex flex-col 	">
          <TailSpin	
                      height="100"
                      width="100"
                      color='grey'
                      ariaLabel='loading'
                    />
                    <br /> <br />
                    <div/>
                <h4 className="">Loading...</h4>
          </div>):(Object.keys(activityData).length>0?
              (<div className=" w-full h-[90%] justify-start items-center inline-flex flex-col 	">
                <div className="w-full h-[10%]   gap-5 inline-flex justify-start items-center ">
                      <div className="grow shrink basis-0 text-gray-900 text-xl font-semibold  ml-[26px]">Activity History</div>
                      {movementActivity&&<div className=" rounded-lg h-[42px]  shadow-sm   border border-gray-300 justify-start items-start inline-flex">
    <button className={"w-[40%] h-full px-4 py-2.5 border-r rounded-l-lg   border-gray-300 justify-center items-center gap-2 flex "+(chartTypeScore===true?"bg-brand-100 ":"bg-white ")+(chartTypeScore===false?"hover:bg-gray-100":"")} onClick={()=>{setChartTypeScore(true)}}>
        <div className="text-gray-700 text-xs font-medium  leading-[18px]">Score</div>
    </button>
    <button className={"w-[60%] h-full px-4 py-2.5 border-r rounded-r-lg  border-gray-300 justify-center items-center gap-2 flex " +(chartTypeScore===false?"bg-brand-100 ":"bg-white " )+(chartTypeScore===true?"hover:bg-gray-100":"")} onClick={()=>{setChartTypeScore(false)}}>
        <div className="text-gray-700 text-xs font-medium  leading-[18px]">Completions</div>
    </button>
                      </div>}
                      <div className=" flex-col  justify-start items-start inline-flex mr-[26px]">
                        {
                          <CustomDropDown options={typeOptions} onchange={setCurrentType}/>
                        }
                      
                      </div>
                </div>
             
                <div className={"w-[calc(100%-48px)]      "+(movementActivity?"h-[65%] mt-5":" mt-5 h-[65%]")}>
               {(data.length>0||countData.length>0)&& <Line
                key={chartTypeScore}

               plugins={[movementActivity?(chartTypeScore===true? custom_canvas_background_color:custom_canvas_background_color2):custom_canvas_background_color2]}
               data={
                  {
                    labels: labels,
                    datasets: [{
                        label: (chartTypeScore?'Score':'Completions'), // Name the series,
                        connectNullData: true,
                        data: (chartTypeScore?data:countData), // Specify the data values array
                        fill: false,
                        borderColor: '#2196f3',
                        
                        backgroundColor: '#2196f3', // Add custom color background (Points and Fill)
                        borderWidth: 1, // Specify bar border width
                        spanGaps: true,
                        segment:{
                          borderDash:ctx=>{
                            return data[ctx.p0DataIndex]===null||data[ctx.p1DataIndex]===null?[10,19]:[]

                          },
                          borderColor:ctx=>{
                            return data[ctx.p0DataIndex]===null||data[ctx.p1DataIndex]===null?'#808080':'#2196f3'

                          }

                        }
                        

                    }]}
                }
      
        options={{
          plugins: {
            tooltip: {
              usePointStyle:true,
              footerFont:{weight: 'normal'},

              callbacks: (chartTypeScore?tooltipCallback:tooltipCallbackCount),
              
          },
            legend: {
              display: false
            },
            beforeDraw: function(chart)  {
              var ctx = chart.chart.ctx;
              ctx.save();
              let xAxis = chart.scales['x-axis-0'];
              let yAxis = chart.scales['y-axis-0'];
              let dataset = chart.data.datasets[0];
              var valueFrom = null;
              var valueFromIndex = 0;
              var xFrom = null;
              let yFrom = null;
              ctx.strokeStyle = dataset.borderColor;
              dataset.data.forEach((value, index) => {
                if (value != null) {
                  var x = xAxis.getPixelForTick(index);
                  var y = yAxis.getPixelForValue(value);
                  if (valueFrom != null) {
                    ctx.lineWidth = dataset.borderWidth;
                    if (index - valueFromIndex > 1) {
                      ctx.setLineDash([5, 5]);
                    } else {
                      ctx.setLineDash([]);
                    }
                    ctx.beginPath();
                    ctx.moveTo(xFrom, yFrom);
                    ctx.lineTo(x, y);
                    ctx.stroke();
                  }
                  valueFrom = value;
                  valueFromIndex = index;
                  xFrom = x;
                  yFrom = y;
                }
              });
              ctx.restore();
            }
           
            
          },
          scales: {
            x: {
              display: true,
              grid:{ 
               display:false
              },
              title: {
                display: true,
                text: 'Week',
                font: {
                  family: 'Inter',
                
                },
              }
            },
            y: {
              max:(chartTypeScore?5000:Math.max(...countData)+1),
               beginAtZero:true,
              ticks:{
                stepSize:chartTypeScore?1000:1,
                
              },
              display: true,
              title: {
                display: true,
                text: (chartTypeScore?'Score':'Completion'),
                font: {
                  family: 'Inter',
                  
                },
              }
            }
          },
          
          maintainAspectRatio: false,
          responsive: true,
          xAxes: [{gridLines: {display: false}}]
        }} />}
                      
                </div>
                <div className="w-[calc(100%-48px)]  border-0  ">
             
              <table className='w-full h-[100%] divide-gray-700 border-0  dark:divide-gray-700 rounded-[10px]'>
                   
                        <tbody className='border-0 '>
                            <React.Fragment>
                        
                           {RenderTable()}
                         
                        
                        </React.Fragment>
                        </tbody>
              </table >
              </div>
  
                 
                  
              </div>
          ):
          (<div className=" w-full h-[90%] justify-start items-center inline-flex flex-col 	"> No Data Found</div>)

          )
      }
      </React.Fragment>
      )
}

export default ActivityPanel