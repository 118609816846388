import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import Spacer from '../components/Spacer';
import Page from '../components/Page';
import Input from '../components/input/Input';
import API from '../services/API';
import { useHistory } from "react-router-dom";
import { TailSpin	 } from  'react-loader-spinner'
import moment from 'moment';
import ExternalOutcomeRow from '../components/table/ExternalOutcomeRow';

const ExternalReportsPage = () => {
    const [errors, setErrors] = useState(null);
    const [userIsClinician, setUserIsClinician] = useState(false);
    const [loading, setLoading] = useState(true);
    const [sendEmail,setSendEmail] = useState(false);
    const [updatePatient,setUpdatePatient] = useState(false);


	const [patients, setPatients] = useState(null);
    const [currentPatient, SetCurrentPatient] = useState(-1);
	const [outcomeReports, setOutcomeReports] = useState(null);
    const [filteredOutcomeReports, setFilteredOutcomeReports] = useState(null);
	const [firstName, setFirstName] = useState(null);
	const [lastName, setLastName] = useState(null);
    const [download, setDownload] = useState(false);



	const [masterPercentage, setMasterPercentage] = useState(0);
    const [slavePercentage, setSlavePercentage] = useState(0);
    const[filter, setFilter]=useState(null)
    const [filteredpatients, setFilteredPatients] = useState(null);

    const[width,setWidth]= useState(null);
  const tableRef = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

const handleResize = () => {
    setDimensions({
    width: window.innerWidth,
    height: window.innerHeight,
    });
  }
useEffect(() => {
  window.addEventListener("resize", handleResize, false);
}, []);

useEffect(() => {
  }, [dimensions]);

  const divStyle = {
	width: (width<window.innerWidth? "fitContent":"90%")

  }
  useEffect(() => {
}, [divStyle]);


useEffect(()=>{
    if(firstName!==null&lastName!==null&&updatePatient==true)
    {
        const data = JSON.stringify({
         
			first_name: firstName,
			last_name: lastName,
            should_receive_approved_outcome_reports: sendEmail
		});
        setUpdatePatient(false);
		updateData(data);

    }
    
},[updatePatient])


useEffect(()=>{
},[width])

useEffect(() => {

    if(tableRef.current!==null)
    {
     
        setWidth(tableRef.current.offsetWidth)
        

    }
}, [tableRef.current,loading]);


    const breadcrumbLinks = () => {


        return [
            {
                label: "Reports",
                url: "/reportsExternal"
            }
            
        ]
    
    


}




useEffect(() => {
    API.get(`/user/`)
			.then(response => {
				console.log(response.data);
                setFirstName(response.data.first_name);
				setLastName(response.data.last_name);
                setSendEmail(response.data.should_receive_approved_outcome_reports);
			
			})
			.catch(error => console.error(error));
    API.get(`/patients/`)
			.then(response => {
				setPatients(response.data.results);
                API.get(`/outcome_reports/`)
			.then(response => {
				setOutcomeReports(response.data.results);
                setLoading(false)

			})
			.catch(
				error => {
                    setLoading(false)

					console.error(error)
					setOutcomeReports([]);
				}
				);
			})
	.catch(
				error => {
					console.error(error)
					setPatients([]);
                    setLoading(false)

				}
	);
		
    
}, []);

useEffect(() => {
    if(patients){
    var filtered= patients.filter(p=>{
        var name = p.first_name+" "+p.last_name;
        name = name.toLowerCase();
        if(filter)
        {
            var f=filter.trim();

            return name.includes(f);
        }
        else
        {
            return true;
        }
    })
    setFilteredPatients(filtered)
    }
}, [patients,filter]);

let history = useHistory();

const updateData = (data) => {
    
    setLoading(true);

    API.patch(`/user/`, data)
        .then((response) => {
            
         
           localStorage.setItem("success","Updated email report preferences" );
           window.location.reload();


        })
        .catch(error => {
            console.error(error.response)
            setErrors(error.response && error.response.data);
            setLoading(false);

        });

}

const LoadPage=(id)=>{

    if(id){

    history.push(`${id && `/outcomes/${id}`}`);
    }

}




const RenderTable = () => {
    var t= []

    filteredpatients.forEach((patient,index) => {

        let filteredOutcome = outcomeReports.filter(function(value)
        { 
            return value.patient_id === patient.id ;
        });
        console.log(filteredOutcome)
        filteredOutcome.sort((a, b) => (a.included_weeks > b.included_weeks) ? 1 : -1)

        t.push(
            <ExternalOutcomeRow patient={patient} Click={SetCurrentPatient} currentPatient={currentPatient} index={index} outcomeCollection={filteredOutcome} SetDownload={setDownload}/>
        )
        
    });
    return t;


}


  return (
    <Page errors={errors} headerObj={{trail:breadcrumbLinks()}}  internal={true}>
    <Spacer headerObj={{trail:breadcrumbLinks()}} internal={true}/>

    <div className="w-full h-full justify-center items-start inline-flex mt-8">
{       loading===false &&firstName!==null&&download!==true?
    <div class={" bg-white h-[90%]   rounded-lg shadow-lg border-2 border-gray-200 flex-col  items-end flex "+(width<window.innerWidth?"w-[90%]":"w-[90%]")} >
    <div className="w-full h-[10%] px-6 pt-5 pb-[19px] justify-start items-center gap-4 inline-flex">
<div className="w-[50%] h-full justify-start items-center gap-4 inline-flex">
    <div className="text-gray-900 text-lg font-medium  leading-7 whitespace-nowrap">Patient Outcome Reports &ensp;</div>
    
     <div className="grow shrink w-[100%] basis-0 h-full  items-center gap-2 flex  ">
     <div className="justify-center items-center flex">
     <input
     className='w-3.5 h-3.5 p-0.5 bg-gray-50 rounded border border-blue-600 justify-center items-center flex'
     type="checkbox"
     checked={sendEmail}
     onChange={()=>{setSendEmail(!sendEmail); setUpdatePatient(true)}}
     />
     </div>
     <div className="text-gray-700 text-xs  grow shrink   w-[100%] leading-tight whitespace-nowrap">Email me when new reports become available</div>
     </div>
    
   
    
</div>
<div className="grow shrink basis-0 h-5 px-[25px] justify-end items-center gap-2 flex">
   
</div>


<div className="w-[25%] flex-col justify-start items-start inline-flex">
    <div className="self-stretch h-full flex-col justify-start items-start gap-1.5 flex">
        <div className="self-stretch h-full flex-col justify-start items-start gap-1.5 flex">
            <div className="self-stretch px-3.5 py-1.5 bg-white rounded-lg shadow border border-gray-300 justify-start items-center gap-2 inline-flex">
                <div className="grow shrink basis-0 h-full justify-start items-center gap-2 flex">
                    <img className="w-6 h-6 relative" src="/icons/search.svg"/>
                   
                <Input
                className="  outline-none border-0 focus:outline-none	 active:border-0 focus:border-0 focus:ring-0 	overflow-hidden	w-full grow shrink basis-0 h-6 text-gray-500 text-base font-normal  leading-normal"
                type="text"
                name="Search by name"
                onChange={setFilter}
                value={filter}
                required
                formErrors={errors} 
                overrideText={"Search by name"}
                />
                </div>
                
               
                
            </div>
        </div>
    </div>
</div>

            </div>{
            filteredpatients&&filteredpatients.length>0?
            <div class="grid grid-col w-full whitespace-nowrap overflow-y-auto	">
                <div class=" whitespace-nowrap w-full	">
                    <div class="whitespace-nowrap w-full	inline-block align-middle ">
                    <div class=" whitespace-nowrap w-full	">
                        
                        <table class="w-full " ref={tableRef}>
                        <thead className='whitespace-nowrap	w-full'>
                        <tr className='shadow-[inset_0_1px_0_#d3d3d3,inset_0_-1px_0_#d3d3d3] bg-gray-25 whitespace-nowrap	sticky top-0 z-[2]'>
                            <th  scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	  px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px] invisible	 ">xxx</th>
                            <th  scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	  px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px] ">Patient</th>
                            <th scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px]">Physician</th>
                            <th scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	  px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px]">Insurer</th>
                            <th scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	  px-6 py-3 text-start text-xs font-medium text-gray-500  leading-[18px]">TPA</th>
                            <th scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	text-opacity-0	 px-6 py-3 text-start text-xs font-medium text-gray-500  invisible leading-[18px]">Created</th>
                            <th scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	text-opacity-0	 px-6 py-3 text-start text-xs font-medium text-gray-500  invisible  leading-[18px]">Report Week</th>
                            <th scope="col" class="sticky top-0 z-[2] whitespace-nowrap	border-x-0	text-opacity-0	 px-6 py-3 text-start text-xs font-medium text-gray-500  invisible  leading-[18px]">Coach</th>

                            <th scope="col" class="sticky top-0 z-[2] text-center	 whitespace-nowrap	border-x-0	  px-6 py-3  text-xs font-medium text-gray-500  leading-[18px]">Actions</th>
                            </tr>
                        </thead>
                        <tbody className='whitespace-nowrap	'>
                            <React.Fragment>
                        {
                            outcomeReports&&filteredpatients&&  RenderTable()
                        }
                        </React.Fragment>
                        </tbody>
                    
                        </table>
                        
                    </div>
                    </div>
                </div>
            </div>:
            	<div className=" w-full h-full justify-center items-center inline-flex flex-col	">
            <p className="px-6 text-gray-900 text-lg font-medium ">No patients found</p>
</div>
            }
        </div>
        :
        <div className=" w-full h-full justify-center items-center inline-flex flex-col	">
	<TailSpin	
				height="100"
				width="100"
				color='grey'
				ariaLabel='loading'
			  />
			  <br /> <br />
			  <div/>
		  <h4 className="">Loading...</h4>
	</div>
}

    </div>


    

</Page>
    )
}

export default withRouter(ExternalReportsPage)