import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { withRouter, useParams } from 'react-router';
import { getDuration } from '../../../utility/Visit';
import moment from 'moment';

const SessionHeader = ({sessionData}) => {


 

  if(sessionData!==null&&sessionData!==undefined){
  return (
    <div className='w-[100%] gap-2.5  justify-center px-5 items-center inline-flex   '>
        <div className="px-4 pt-4 pb-20 max-[1200px]:flex-col w-[20%] h-[75%] max-[1200px]:h-[90%] bg-white rounded-xl shadow border border-gray-200  items-start gap-4 max-[1200px]:gap-2 flex ">
        <div className=" w-14 h-14 max-[1200px]:w-10 max-[1200px]:h-10  bg-gray-100 rounded-full shrink-0 grow-0 justify-center items-center inline-flex flex-col">
        <img className="w-1/2 h-1/2  flex-col justify-start items-start " src= "/icons/clock-refresh.svg" />
        </div>
        <div className="flex-col justify-start items-start gap-2 inline-flex break-words">
        <div className="text-gray-600 text-sm min-[1800px]:text-xl font-medium  leading-tight text-wrap break-words	">Total Motion Time</div>
        <div className="text-gray-900 text-4xl  max-[1200px]:text-3xl font-semibold  leading-[44px]">{sessionData.motion_time>60?((moment.duration(sessionData.motion_time*1000).hours()>0? moment.duration(sessionData.motion_time*1000).hours()+"h ":"")+( moment.duration(sessionData.motion_time*1000).minutes()>0? moment.duration(sessionData.motion_time*1000).minutes()+"m ":"")):moment.duration(sessionData.motion_time*1000).seconds()+"s" }</div>
        </div>
        </div>
        <div className="px-4 pt-4 pb-20 w-[20%] max-[1200px]:flex-col  h-[75%] max-[1200px]:h-[90%] bg-white rounded-xl shadow border border-gray-200  items-start gap-4 max-[1200px]:gap-2 flex">
        <div className=" w-14 h-14 max-[1200px]:w-10 max-[1200px]:h-10   bg-gray-100 rounded-full shrink-0 grow-0 justify-center items-center inline-flex flex-col">
        <img className="w-1/2 h-1/2 relative flex-col justify-start items-start flex" src= "/icons/compass.svg" />
        </div>
        <div className="flex-col justify-start items-start gap-2 inline-flex break-words">
        <div className="text-gray-600 text-sm font-medium  leading-tight text-wrap break-words min-[1800px]:text-xl 	">ROM Measures</div>
        <div className="text-gray-900 text-4xl max-[1200px]:text-3xl font-semibold  leading-[44px]">{sessionData.rom_motion_types_calibrated}</div>
        </div>
        </div>
        <div className="px-4 pt-4 pb-20 w-[20%] max-[1200px]:flex-col h-[75%] max-[1200px]:h-[90%] bg-white rounded-xl shadow border border-gray-200 items-start gap-4 max-[1200px]:gap-2 flex">
        <div className="w-14 h-14 max-[1200px]:w-10 max-[1200px]:h-10  bg-gray-100 rounded-full shrink-0 grow-0 justify-center items-center inline-flex flex-col">
        <img className="w-1/2 h-1/2 relative flex-col justify-start items-start flex" src= "/icons/activity-heart.svg" />
        </div>
        <div className="flex-col justify-start items-start gap-2 inline-flex">
        <div className="text-gray-600 text-sm font-medium  leading-tight text-wrap break-words	min-[1800px]:text-xl ">Activities Completed</div>
        <div className="text-gray-900 text-4xl max-[1200px]:text-3xl font-semibold  leading-[44px]">{sessionData.activity_completions}</div>
        </div>
        </div>
        <div className="px-4 pt-4 pb-20 w-[20%] max-[1200px]:flex-col h-[75%] max-[1200px]:h-[90%] bg-white rounded-xl shadow border border-gray-200  items-start gap-4 max-[1200px]:gap-2 flex">
        <div className=" w-14 h-14 max-[1200px]:w-10 max-[1200px]:h-10   bg-gray-100 rounded-full shrink-0 grow-0 justify-center items-center inline-flex flex-col">
        <img className="w-1/2 h-1/2 relative flex-col justify-start items-start flex" src= "/icons/bar-chart-12.svg" />
        </div>
        <div className="flex-col justify-start items-start gap-2 inline-flex">
        <div className="text-gray-600 text-sm font-medium  leading-tight text-wrap min-[1800px]:text-xl 	break-words">Routines Completed</div>
        <div className="text-gray-900 text-4xl max-[1200px]:text-3xl font-semibold  leading-[44px]">{sessionData.personal_routine_completions        }</div>
        </div>
        </div>
        <div className="px-4 pt-4 pb-20 w-[20%] max-[1200px]:flex-col h-[75%] max-[1200px]:h-[90%] bg-white rounded-xl shadow border border-gray-200  items-start gap-4 max-[1200px]:gap-2 flex">
        <div className=" w-14 h-14 max-[1200px]:w-10 max-[1200px]:h-10   bg-gray-100 rounded-full shrink-0 grow-0 justify-center items-center inline-flex flex-col">
        <img className="w-1/2 h-1/2 relative flex-col justify-start items-start flex" src= "/icons/award-03.svg" />
        </div>
        <div className="flex-col justify-start items-start gap-2 inline-flex">
        <div className="text-gray-600 text-sm font-medium  leading-tight text-wrap break-words min-[1800px]:text-xl 	">Personal Bests</div>
        <div className="text-gray-900 text-4xl max-[1200px]:text-3xl font-semibold  leading-[44px]">{sessionData.personal_bests}</div>
        </div>
        </div>


    </div>
  )
}
}

export default SessionHeader